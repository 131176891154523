import request from '@/utils/request';

export function fetchList() {
  return request({
    url: '/v1/redis/getAll',
    method: 'get',
  });
}

export function updateRedis(data) {
  return request({
    url: 'v1/redis/updateRedis',
    method: 'patch',
    data,
  });
}
